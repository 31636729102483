.lan-btn {
  background-color: #038f21;
  margin-top: -10px;
  margin-left: 5px;
  color: white;
  border: none;
  padding: 0.5rem 0.8rem;
  cursor: pointer;
  border-radius: 5px;
}

.lan-btn:hover {
  background-color: #015513;
}