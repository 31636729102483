/* Inform.css */
.inform3 {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  overflow: hidden;
  opacity: 0; /* Hidden initially */
  transition: opacity 1s ease-out; /* Smooth transition */
}

/* Transition for elements in view */
.inform3.in-view {
  opacity: 1;
}

/* Right side styles */
.inform3-right {
  flex: 3;
  width: 50%;
  margin-left: 2rem;
  transform: translateX(100%); /* Start position off-screen left */
  transition: transform 1s ease-out; /* Smooth transition */
}

.inform3.in-view .inform3-right {
  transform: translateX(0); /* End position in view */
}

.inform3-right h1 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 3rem; /* Set font size to 20px */
  color: rgb(76, 77, 85); /* Set color to gray */
  margin-bottom: 1rem;
}

.inform3-right h2 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: left;
  color: rgb(151, 150, 150);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}


.bullet-points {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.bullet-points li {
  margin-bottom: 4rem;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: left;
  color: rgb(151, 150, 150);
}
.highlight {
  color: rgb(76, 77, 85); /* Change this to your preferred color */
}

/* Left side styles */
.inform3-left {
  flex: 1;
  width: 50%;
  align-self: center;
  transform: translateX(-100%); /* Start position off-screen right */
  transition: transform 1s ease-out; /* Smooth transition */
}

.inform3.in-view .inform3-left {
  transform: translateX(0); /* End position in view */
}

.inform3-left img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5); /* Darker, larger shadow */
}

/* Responsive styles for smooth resizing */
@media (max-width: 1200px) {
  .inform3-right h2,h1 {
    font-size: 3vw; /* Font size decreases with viewport width */
  }

  .inform3-left img {
    width: 80%; /* Image resizes smoothly */
  }
}

/* Responsive styles for smooth resizing */
@media (max-width: 768px) {
  .inform3 {
    flex-direction: column; /* Stack text and image vertically */
    margin-bottom: 5rem;
  }

  .inform3-left, .inform3-right {
    width: 100%;
    transform: translateX(100); /* Ensure both sections are visible */
  }

  .inform3-left {
    order: 2; /* Move image to the bottom */
  }

  .inform3-right {
    order: 1; /* Move text to the top */
    margin-left: 1rem;
  }

  .inform3-right h1 {
    font-size: 2rem; /* Decrease font size smoothly */
  }

  .inform3-right h2 {
    font-size: 1.2rem; /* Adjust subtitle size */
  }

  .bullet-points li {
    font-size: 1rem; /* Adjust bullet points font size */
  }

  .inform3-left img {
    width: 100%; /* Adjust image size */
  }
}

/* For even smaller screens */
@media (max-width: 480px) {
  .inform3-right h1 {
    font-size: 1.6rem; /* Further decrease heading font size */
    margin-bottom: 2rem;
    margin-right: 50px;
  }

  .inform3-right h2 {
    font-size: 1rem; /* Further adjust subtitle size */
    text-align:left;
  }

  .bullet-points li {
    font-size: 0.9rem; /* Adjust bullet points font size */
    padding-left: 0.2rem;
    margin-right: 50px;
  }
}
