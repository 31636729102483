/* Main container for the team page */
.team-page {
  text-align: center;
  padding: 20px;
}

.team-page h1 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: rgb(76, 77, 85);
  font-size: 2rem; /* Reduce font size for tablets and mobile */
}

/* Title */
.team-title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

/* Team cards container */
.team-cards {
  display: flex;
  justify-content: center;
  gap: 20px; /* 20px gap between cards */
  flex-wrap: nowrap; /* Prevent wrapping on larger screens */
}

/* Card styles */
.card {
  width: 300px;
  height: 350px;
  perspective: 1000px; /* 3D perspective for the flip effect */
  cursor: pointer;
  text-align: center;
  position: relative;
}

.card h2 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: ivory;
  font-size: 1.2rem; /* Reduce font size for tablets and mobile */
}

.card p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 100;
  color: rgb(216, 199, 199)
}

.card-front, .card-back {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transition: transform 0.6s;
}

.card-back h1{
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.5rem; /* Set font size to 20px */
  color: ivory; /* Set color to gray */
}

.card-back h2{
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    text-align: center;
    color: rgb(151, 150, 150);
    font-size: 1rem;
    margin-bottom: 1rem;
}


.card-details{
  width: 80%;
  height: 40%;
  background-color: ivory; /* Slightly transparent background */
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.8);
}

/* Flip effect */
.card.flipped .card-front {
  transform: rotateY(180deg);
}

.card.flipped .card-back {
  transform: rotateY(0deg);
}

/* Front and Back card */
.card-front {
  background-color: rgb(122, 120, 120); /* Slightly transparent background */
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.8); /* Lighter shadow for content */
  transform: rotateY(0deg);
}

.card-back {
  background-color: #333;
  color: #fff;
  transform: rotateY(180deg);
}

/* Team image */
.team-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.8);
  margin-bottom: 15px;
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
  .team-cards {
    flex-direction: column; /* Stack cards vertically on mobile */
    align-items: center; /* Center the cards in mobile view */
    gap: 20px; /* Maintain gap between stacked cards */
  }

  .card {
    width: 100%; /* Card takes full width on mobile */
    max-width: 250px; /* Limit max width to 250px for better design */
  }

  .team-image {
    width: 200px;
    height: 200px;
  }
}
