/* Main container for the application page */
.endcard-page {
  align-items: center;
  height: auto; /* Full viewport height */
  width: auto;
  overflow: hidden;
  opacity: 0; /* Hidden initially */
  transition: opacity 2s ease-out; /* Smooth transition */
}

.endcard-page.in-view {
  opacity: 1;
  transition: opacity 2s ease-out; 
}

/* Headline style */
.endcard-page .endcard-container .endline h1 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
  font-weight: 500;
  color: rgb(27, 27, 27);
  font-size: 2.5rem;
  margin-bottom: 1rem;
  transform: translateY(100%); /* Start position off-screen left */
  transition: transform 1s ease-out; /* Smooth transition */
}

.endcard-page.in-view .endline h1 {
  transform: translateY(0); /* End position in view */
}


/* Responsive design for iPads */
@media (max-width: 1024px) {
  .endline h1 {
    font-size: 2.5rem; /* Adjust font size */
  }
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .endline h1 {
    font-size: 2rem; /* Adjust font size */
  }
  
}