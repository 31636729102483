.table-container {
  margin: 20px;
  overflow-x: auto; /* To handle responsive design */
  animation: slideUp 1s ease-out;
}

.table-header {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgba(63, 62, 62, 0.726);
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 100;
  animation: fadeIn 1.5s ease-out;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 12px;
  text-align: left;
}

th {
  background-color: #f2f2f2; /* Light gray background for header */
}

.column1 {
  color: red; /* Text color for Column 1 */
}

.column2 {
  color: green; /* Text color for Column 2 */
}

.column3 {
  color: blue; /* Text color for Column 3 */
}

.column4 {
  color: purple; /* Text color for Column 4 */
}

tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternate row color */
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  th, td {
    font-size: 8px;
  }
  .table-header{
    font-size: 1.8rem;
  }
}
