/* Contact.css */

.contact {
  padding: 0.5rem;
  text-align: center;
  width: auto;
  
}

.contact-top {
  margin-bottom: 2rem;
  width: auto;
}

.contact-heading {
  font-size: 4rem;
  color: #333;
  animation: slide-in-left 1.5s ease-out;
}

/* Animations */
@keyframes slide-in-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes rise-up {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.contact-bottom {
  display: flex;
  justify-content: space-around; /* Space out the contact info divs */
  background-color: rgb(76, 77, 85);
  gap: 1.5rem; /* Space between the contact info divs */
  width: auto;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
}

.contact-info {
  flex: 1; /* Each contact info div takes up equal space */
  padding: 1.5rem;
  text-align: left;
  animation: rise-up 1.5s ease-out;
}

.contact-info h1 {
  font-size: 1.5rem;
  color: #dddcdc;
  margin-bottom: 0.5rem;
}

.contact-info p {
  font-size: 1rem;
  color: #dddcdc;
  line-height: 1.5;
}

/* Responsive design for mobile */
@media (max-width: 768px) {
  .contact-bottom {
    flex-direction: column;
    align-items: center;
  }

  .contact-info {
    width: 100%; /* Each contact-info div will take full width */
    text-align: center;
    padding: 0.1rem;
  }

  .contact-heading {
    font-size: 2rem;
  }
  .contact-info p{
    font-size: 0.9rem;
  }
}
