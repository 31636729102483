/* Slideshow container */
.slideshow {
  position: relative;
  width: 100%;
  height: auto; /* Let the height be determined by the content (image) */
  overflow: hidden;
}

/* Slide content for the front image */
.slide {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.slide img {
  width: 100%;
  height: auto; /* Ensure aspect ratio is maintained */
  object-fit: contain; /* Contain the image within the slide */
  object-position: center; /* Center the image */
  transition: transform 0.3s ease-in-out;
}

/* Responsive styles */
@media (max-width: 1024px) {
  /* For iPad and smaller tablets */
  .slide img {
    width: 100%; /* Use full width of container */
  }
}

@media (max-width: 768px) {
  /* For tablets */
  .slide img {
    width: 100%;
  }
}

@media (max-width: 480px) {
  /* For mobile screens */
  .slide img {
    width: 100%;
    max-height: 60vh; /* Adjust the maximum height for mobile */
    object-fit: cover; /* Crop the image to fit mobile screens */
  }
}
