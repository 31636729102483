/* Main container for the product page */
.product-page2 {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Top section styling */
.top-section2 {
  position: relative;
  width: 100%;
  height: 500px;
  text-align: center;
  overflow: hidden;
}

.bottom-section2 h2 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  padding-left: 3.5rem;
  text-align: left;
  color: rgb(76, 77, 85);
  font-size: 1.2rem; /* Reduce font size for tablets and mobile */
  margin-bottom: 1rem;
}
.bottom-section2 hr {
  margin: 1rem 0;
  border: 0;
  border-top: 2px solid rgb(76, 77, 85);
}

/* Remove default bullets */
.bottom-section2 .product-points2 {
  list-style-type: none;
  padding-left: 1.5rem;
}

.product-points2 li {
  position: relative;
  margin-bottom: 0.2rem;
  padding-left: 2rem; /* Add padding to create space for the custom bullet */
  font-family:Roboto Condensed;
  text-align: left;
  color: rgb(128, 116, 116);
}

.product-points2  span {
  color: rgba(0, 0, 0,0.8);
}

/* Custom bullet points with image icons */
.product-points2 li::before {
  content: '';
  position: absolute;
  left:-1rem; 
  width: 40px; /* Set the width of the bullet icon */
  height: 40px; /* Set the height of the bullet icon */
  background-size: cover; /* Ensure the image fills the container */
  background-repeat: no-repeat;
}

/* Headline styling */
.product_headline2 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 3.5rem;
  background-image: url('../assets/application3.jpg'); /* Set your background image here */
  background-size: cover;         /* Ensures the background image covers the text area */
  background-clip: text;          /* Clips the background to the text */
  -webkit-background-clip: text;  /* For Safari and Chrome */
  color: transparent;
  position: relative;
  z-index: 2;
  opacity: 0;
  transform: translateY(100px);
  transition: transform 1s ease-out, opacity 2s ease-out;
}

/* Image styling */
.product-image2 {
  position: absolute;
  margin-bottom: 0;
  bottom: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: auto;
  z-index: 1;
  opacity: 0;
  transition: bottom 2s ease-out, opacity 2s ease-out;
}

/* Bottom section styling */
.bottom-section2 {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
  max-width: 1200px;
  opacity: 0;
  transition: opacity 2s ease-out;
}

/* Left text div */
.left-text2, .right-text2 {
  width: 48%;
  font-size: 1.2rem;
  transform: translateX(-100%);
  transition: transform 2s ease-out, opacity 2s ease-out;
}

.right-text2 {
  transform: translateX(100%);
}

/* Activate animations when in view */
.product-page2.in-view .product_headline2 {
  transform: translateY(0);
  opacity: 1;
}

.product-page2.in-view .product-image2 {
  bottom: 0;
  opacity: 1;
}

.product-page2.in-view .bottom-section2 {
  opacity: 1;
}

.product-page2.in-view .left-text2 {
  transform: translateX(0);
}

.product-page2.in-view .right-text2 {
  transform: translateX(0);
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .top-section2 {
    height: 300px;
  }

  .product_headline2{
    font-size: 2.8rem;
  }

  .product-image2 {
    width: 100%;
    position: absolute;
    margin-bottom: 0;
  }

  .bottom-section2 {
    flex-direction: column;
    align-items: center;
  }

  .left-text2, .right-text2 {
    width: 100%;
    text-align: center;
    transform: translateX(0);
    margin-bottom: 20px;
  }
  .product-points2 li {
    font-size: 1rem;
  }
}
