/* Inform2.css */
.inform2 {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  overflow: hidden;
  opacity: 0; /* Hidden initially */
  transition: opacity 2s ease-out; /* Smooth transition */
}

/* Transition for elements in view */
.inform2.in-view {
  opacity: 1;
}

/* Left side styles */
.inform2-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top-text {
  display: flex;
  justify-content: space-between;
}

.inform2.in-view .inform2-left {
  transform: translateX(0); /* End position in view */
}

.text-line {
  display: flex;
  flex-direction: column; /* Align text vertically */
  gap: 0.5rem; /* Space between lines */
}

.text-line span {
  display: block; /* Ensure each line is on a new line */
}
.inform2-text1-left {
  flex: 1;
  text-align: right;
}

.inform2-text1-left h2 {
  font-size: 2.5rem; /* Set font size to 20px */
  color: gray; /* Set color to gray */
  margin-right: 1rem; /* Add space to the right of "We" */
  text-align: right;
}

.inform2-text1-right {
  flex: 4;
  text-align: left;
}

.inform2-text1-right h2 {
  font-size: 2.5rem;
  color: gray;
  margin-bottom: 1rem;
}

.highlight {
  color:rgb(76, 77, 85); /* Change this to your preferred color */
}
/* Down text div */
.down-text {
  margin-top: 0;
}

.down-text h2 {
  font-size: 3rem;
  color: rgb(76, 77, 85);
  text-align: left;
}

/* Right side styles */
.inform2-right {
  width: 50%;
  display: flex;
  align-items: center;
  transform: translateX(100%); /* Start position off-screen right */
  transition: transform 1s ease-out; /* Smooth transition */
}

.inform2.in-view .inform2-right {
  transform: translateX(0); /* End position in view */
}

.inform2-right img {
  width: 90%;
  height: auto; /* Maintain aspect ratio */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5); /* Darker, larger shadow */
}

/* Responsive styles for smooth resizing */
@media (max-width: 1200px) {
  .inform2-left h2, .inform2-right h2 {
    font-size: 3vw; /* Font size decreases with viewport width */
  }

  .down-text h2 {
    font-size: 4vw; /* Text smoothly scales */
  }

  .inform2-right img {
    width: 80%; /* Image resizes smoothly */
  }
}

/* Keyframes for continuous left-to-right scrolling */
@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  33% {
    transform: translateX(-10%);
    color: rgb(247, 54, 54,0.5);
  }
  66% {
    transform: translateX(20%);
    color: rgb(247, 54, 54,0.5);
  }
  100% {
    transform: translateX(0%);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .inform2 {
    flex-direction: column; /* Stack image and text vertically on mobile */
    margin-bottom: 5rem;
  }

  .inform2-left, .inform2-right {
    width: 100%;
    transform: translateX(0); /* Ensure both sections are visible */
  }

  .inform2-right {
    order: -1; /* Move image to the top */
  }
  .inform2-left h1 {
    font-size: 3rem; /* Further reduce font size for mobile */
  }

  .inform2-left h2 {
    font-size: 2rem; /* Further reduce font size for mobile */
  }

  .inform2-left hr {
    margin: 0.5rem 0;
  }

  .inform2 .down-text h2{
    font-size: 2.5rem;
    animation: color-change 1s infinite;
    animation: scroll-left 4s linear infinite;
  }

  .inform2-right img {
    width: 100%; /* Adjust image size for mobile */
  }
}
