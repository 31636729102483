/* Mission.css */

.mission {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  overflow: hidden;
}

/* Top div styles */
.mission-top {
  margin-bottom: 2rem;
}
.mission-top h1 {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 2rem; /* Set font size to 20px */
  color: rgb(76, 77, 85); /* Set color to gray */
  margin-bottom: 1rem;
  text-align: center;
  transform: translateX(100);
}
/* Bottom div styles */
.mission-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  transition: opacity 1s ease-out;
}
/* Transition for elements in view */
.mission.in-view h1 {
  transform: translateX(0); /* End position in view */
}

.mission.in-view .mission-image {
  transform: translateX(0); /* End position in view */
}

.mission.in-view .mission-content {
  transform: translateY(0); /* End position in view */
}

/* Image styles */
.mission-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%); /* Start position off-screen left */
  transition: transform 1s ease-out; /* Smooth transition */
}

.mission-image img {
  width: 90%;
  height: auto; /* Maintain aspect ratio */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5); /* Darker, larger shadow */
}

/* Content styles */
.mission-content {
  flex: 2;
  margin-left: 2rem;
  gap: 2rem;
  transform: translateX(100%); /* Start position off-screen bottom */
  transition: transform 1s ease-out; /* Smooth transition */
}

.mission-content-top,
.mission-content-bottom {
  padding: 1rem;
  background-color: rgb(122, 120, 120); /* Slightly transparent background */
  border-radius: 4px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Lighter shadow for content */
}
.mission-content h1 {
  font-size: 2rem;
  color: rgb(243, 243, 245);
  margin-bottom: 1rem;
  text-align: left;
}
.mission-content .highlight {
  color: rgb(243, 243, 245); /* Change this to your preferred color */
}
.mission-content hr {
  border: 0;
  border-top: 2px solid rgb(214, 215, 218);
  margin: 1rem 0;
  text-align: left;
}

.mission-content p {
  font-size: 1rem;
  color: rgb(214, 215, 218);
  line-height: 1.5;
  text-align: left;
}

/* Responsive styles for smooth resizing */
@media (max-width: 1200px) {
  .mission-content h1,
  .mission-content h2 {
    font-size: 3vw; /* Font size decreases with viewport width */
  }

  .mission-image img {
    width: 100%; /* Image resizes smoothly */
  }
}

@media (max-width: 768px) {
  .mission-bottom {
    flex-direction: column; /* Stack image and text vertically */
    margin-bottom: 5rem;
    margin-left: -2rem;
  }

  .mission-image, .mission-content {
    width: 100%;
  }
  .mission-image{
    margin-top: 2rem;
    margin-left: 2rem;
  }

  .mission-image {
    order: 2; /* Move image to the bottom */
  }

  .mission-content {
    order: 1; /* Move text to the top */
  }

  .mission-content h1 {
    font-size: 2rem; /* Decrease font size smoothly */
  }

  .mission-content h2 {
    font-size: 1.2rem; /* Adjust subtitle size */
  }
}

@media (max-width: 480px) {
  .mission-content h1 {
    font-size: 1.6rem; /* Further decrease heading font size */
  }

  .mission-content h2 {
    font-size: 1rem; /* Further adjust subtitle size */
  }
}