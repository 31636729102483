/* Headline style */
.learn-headline h1 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 100;
  text-align: left;
  color: #4c4d55;
  font-size: 3rem;
  margin-bottom: 1rem;
  padding-left: 4rem;
}

/* Subheadline style */
.learn-headline h2 {
  font-family: 'Arial', sans-serif;
  font-weight: 100;
  color: #6a6a6a;
  font-size: 2rem;
  padding-left: 4rem;
}

/* Text section style */
.learn-text {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  padding: 20px;
}

.learn-text .learn-highlight {
  color: rgb(0, 0, 0); /* Highlighted text color */
  font-weight: bold;
}

.learn-text .learn-points li {
  color: rgb(47, 47, 47);
  list-style-type: none;
  font-size: 1.5rem;
  position: relative;
  padding-left: 20px; /* Adjust spacing */
  margin-bottom: 20px;
}

.learn-text .learn-points li::before {
  content: '';
  position: absolute;
  height: 20px;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px; /* Width of the left border */
  margin-bottom: 20px;
  background-color: #ff6600; /* Color of the border */
}
.learn-text .learn-highlight {
  color: rgb(0, 0, 0); /* Change this to your preferred color */
  font-weight: bold;
}

.learn-text .learn-points2 {
  color: rgb(47, 47, 47);
  list-style-type: none;
  font-size: 1rem;
  position: relative;
  padding-left: 20px; /* Adjust spacing */
  margin-bottom: 10px;
}


/* Responsive design for tablets */
@media (max-width: 1024px) {
  .learn-headline h1 {
    font-size: 2.5rem;
  }
  .learn-text {
    padding: 15px;
  }
  .learn-page {
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .learn-headline h1 {
    font-size: 2rem;
  }
  .learn-headline h2 {
    font-size: 1rem;
    font-weight: 400;
  }
  .learn-text {
    padding: 8px;
  }
  .learn-page {
    display: flex;
    padding-left:0;
    padding-right: 20px;
  }
}
