/* General page layout */
.Certificate-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  opacity: 0;
  transition: opacity 2s ease-out;
}

/* Header styling */
.Certificate-header {
  margin-bottom: 30px;
}

.Certificate-title {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgba(63, 62, 62, 0.726);
  font-size: 3rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 100;
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: transform 1s ease-out, opacity 1.5s ease-out;
}

/* Content section layout */
.Certificate-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  transition: opacity 2s ease-out;
}

.Certificate-section p{
  margin-bottom: 0.2rem;
  font-family:Roboto Condensed;
  text-align: left;
  color: rgb(128, 116, 116);
}

.left-Certificate, .right-Certificate {
  width: 45%;
  font-size: 1.2rem;
}

.right-Certificate {
  opacity: 0;
}

.Certificate-image1 {
  width: 400px;
  height: 100px;
  margin-bottom: 10px;
}

.Certificate-image2 {
  width: 300px;
  height: 100px;
  margin-bottom: 10px;
}

/* When elements are in view */
.Certificate-content.in-view {
  opacity: 1;
}

.Certificate-content.in-view .Certificate-title {
  transform: translateY(0);
  opacity: 1;
}

.Certificate-content.in-view .left-Certificate, .Certificate-content.in-view .right-Certificate {
  transform: translateX(0);
  opacity: 1;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .Certificate-section {
    flex-direction: column;
    align-items: center;
  }

  .left-Certificate, .right-Certificate {
    width: 100%;
    text-align: center;
    transform: translateX(0);
  }

  .Certificate-title {
    font-size: 1.2rem;
  }

  .Certificate-image1 {
    width: 200px;
    height: 50px;
  }
  
  .Certificate-image2 {
    width: 150px;
    height: 50px;
  }

  .Certificate-section p{
    margin-left: 20px;
  }
}
