/* Main container for the application page */
.application-page {
  display: flex;
  justify-content: flex-start; /* Align content to the left side */
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: auto;
  background-image: url('../assets/application1.jpg'); /* Replace with your background image */
  background-size: cover;
  background-position: center;
  padding-left: 50px;
  overflow: hidden;
  opacity: 0; /* Hidden initially */
  transition: opacity 2s ease-out; /* Smooth transition */
}

.application-page.in-view {
  opacity: 1;
  transition: opacity 2s ease-out; 
}

/* Content container on the left */
.content-container {
  display: flex;
  flex-direction: column;
  color: white;
  max-width: 600px;
  width: 100%;
}

/* Headline style */
.content-container .headline1 h1 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: left;
  color: rgb(76, 77, 85);
  font-size: 3rem;
  margin-bottom: 1rem;
  transform: translateX(-100%); /* Start position off-screen left */
  transition: transform 1s ease-out; /* Smooth transition */
}

.application-page.in-view .headline1 h1 {
  transform: translateX(0); /* End position in view */
}

/* Text section style */
.text {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0)); /* Fading effect */
  padding: 20px;
  transform: translateX(-100%); /* Start position off-screen left */
  transition: transform 2s ease-out; /* Smooth transition */
}

.application-page.in-view .text {
  transform: translateX(0); /* End position in view */
}

.text .highlight {
  color: rgb(0, 0, 0); /* Change this to your preferred color */
  font-weight: bold;
}

.text .bullet-points li {
  color: rgb(47, 47, 47);
  list-style-type: none;
  position: relative;
  padding-left: 20px; /* Adjust spacing */
  margin-bottom: 20px; /* Space between list items */
}

.text .bullet-points li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px; /* Width of the left border */
  margin-bottom: 20px;
  background-color: #ff6600; /* Color of the border */
}

/* Responsive design for iPads */
@media (max-width: 1024px) {
  .headline1 h1 {
    font-size: 2.5rem; /* Adjust font size */
  }
  .text {
    padding: 15px; /* Adjust padding */
  }
  .application-page {
    padding-left: 30px;
    padding-right: 30px; /* Add right padding */
    background-size: cover; /* Ensure cover for the background */
    height: auto; /* Allow height to adjust */
    width: auto;
  }
}

/* Responsive design for mobile screens */
@media (max-width: 768px) {
  .content-container .headline1 h1 {
    font-size: 2rem; /* Adjust font size */
  }
  .text {
    padding: 8px; /* Adjust padding */
  }
  .application-page {
    padding-left: 20px;
    padding-right: 20px; /* Add right padding */
    height: auto; /* Allow height to adjust */
    width: auto;
    
  }
  
}