/* TitlePage.css */
.Application-overlay {
  position: absolute;
  top: 75%;  /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Perfect centering */
  z-index: 10; /* Higher z-index to ensure it appears over the slideshow */
  text-align: left;
  margin-left: 0;
  width: 90%;
  
}

.Application-us {
  color: #fff; /* White text to contrast with the slideshow */
  font-size: 2.5rem;
  margin-bottom: 0.1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Shadow for better visibility */
}

.Application-description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  margin-left: 1rem;
  margin: 0 auto;
  line-height: 1.6;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
}


/* Responsive Design */
@media (max-width: 1024px) {
  .Application-overlay {
    top: 36%;
  }
}
@media (max-width: 768px) {
  .Application-overlay {
    width: 100%;
    top: 20%;
  }
  .Application-us {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .Application-description {
    font-size: 0.6rem;
    margin-left: 1rem;
  }
}

@media (max-width: 480px) {
  .Application-overlay {
    width: 100%;
    top: 20%;
  }
  .Application-us {
    font-size: 1.5rem;
    margin-left: 1rem;
  }


  .Application-description {
    font-size: 0.6rem;
    margin-left: 1rem;
  }
}
