/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); /* Transparent black background */
  z-index: 1000;
  transition: top 0.3s;
}

.navbar.hidden {
  top: -100px; /* Moves the navbar out of view */
  transition: top 0.3s ease-in-out;
}

.navbar.visible {
  top: 0; /* Navbar visible at the top */
  transition: top 0.3s ease-in-out;
}

.logo {
  margin-left: 1rem;
}

.logo-img {
  width: 150px;
}

.nav-links {
  display: flex;
  list-style-type: none;
  gap: 2rem;
  margin-right: 2rem;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.nav-links li a:hover{
  color: #ff6600;
}

.contact-btn {
  background-color: #ff6600;
  margin-top: -10px;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 20px;
}

.contact-btn:hover {
  background-color: #e65c00;
}

/* Mobile menu icon (hamburger) */
.nav-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 1rem;
}

.burger {
  display: flex;
  margin-left:  200%;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

.burger-line {
  height: 3px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

/* When the menu is open, transform to 'X' */
.burger.toggle .burger-line:nth-child(1) {
  transform: rotate(45deg) translate(7px, 5px);
}

.burger.toggle .burger-line:nth-child(2) {
  opacity: 0;
}

.burger.toggle .burger-line:nth-child(3) {
  transform: rotate(-45deg) translate(7.6px, -5px);
}

/* Mobile menu styles */
.mobile-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 80%;
  right: 7.5%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 200px;
  padding: 1rem;
  border-radius: 8px;
}

.mobile-menu.open {
  display: flex;
  align-items: center;
}

.mobile-menu li {
  margin-bottom: 1rem;
  list-style: none;
}

.mobile-menu li a {
  font-size: 20px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide menu links on mobile */
  }

  .nav-icon {
    display: flex; /* Show mobile menu icon */
    margin-right: 100px;
  }

  .logo-img {
    width: 100px; /* Smaller logo for mobile */
  }
  .contact-btn {
    font-size: 20px;
    font-weight: bold;
    background-color: #ff6600;
    color: white;
    border: none;
    padding: 0.4rem 1rem;
    cursor: pointer;
    border-radius: 20px;
  }
}
