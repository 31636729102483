/* Inform1.css */
.inform4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  overflow: hidden;
  opacity: 0; /* Hidden initially */
  transition: opacity 1s ease-out; /* Smooth transition */
}

/* Transition for elements in view */
.inform4.in-view {
  opacity: 1;
}

/* Left side styles */
.inform4-left {
  width: 50%;
  transform: translateX(-100%); /* Start position off-screen left */
  transition: transform 1s ease-out; /* Smooth transition */
}

.inform4.in-view .inform4-left {
  transform: translateX(0); /* End position in view */
}

.inform4-left h1 {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 2rem; /* Reduce font size for tablets and mobile */
  color: rgb(151, 150, 150); /* Set color to gray */
  margin-bottom: 1rem;
}

.inform4-left h2 {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: rgb(76, 77, 85);
  font-size: 2rem; /* Reduce font size for tablets and mobile */
  margin-bottom: 2rem;
}

.inform4-left hr {
  margin: 1rem 0;
  border: 0;
  border-top: 2px solid rgb(76, 77, 85);
}

/* Remove default bullets */
.bullet-points {
  padding-left: 1.5rem;
}

.bullet-points li {
  position: relative;
  margin-bottom: 4rem;
  padding-left: 2rem; /* Add padding to create space for the custom bullet */
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  color: rgb(128, 116, 116);
}

/* Custom bullet points with image icons */
.bullet-points li::before {
  content: '';
  position: absolute;
  left:-1rem; 
  width: 40px; /* Set the width of the bullet icon */
  height: 40px; /* Set the height of the bullet icon */
  background-size: cover; /* Ensure the image fills the container */
  background-repeat: no-repeat;
}

/* Responsive: Adjust bullet icon size for smaller screens */
@media (max-width: 768px) {
  .bullet-points li::before {
    width: 12px; /* Smaller icon for mobile */
    height: 12px;
    top: 0.2rem; /* Adjust vertical alignment for smaller screens */
  }
}

.highlight {
  color: rgb(0, 0, 0); /* Change this to your preferred color */
}

/* Right side styles */
.inform4-right {
  width: 50%;
  transform: translateX(100%); /* Start position off-screen right */
  transition: transform 1s ease-out; /* Smooth transition */
}

.inform4.in-view .inform4-right {
  transform: translateX(0); /* End position in view */
}

.inform4-right img {
  width: 100%; /* Reduce image size for tablets and mobile */
  height: auto; /* Maintain aspect ratio */
}

/* Responsive styles */
@media (max-width: 768px) {
  .inform4 {
    flex-direction: column; /* Stack image and text vertically on mobile */
  }

  .inform4-left, .inform4-right {
    width: 100%;
    transform: translateX(100); /* Ensure both sections are visible */
  }

  .inform4-right {
    order: -1; /* Move image to the top */
  }
  .inform4-left h1 {
    font-size: 3rem; /* Further reduce font size for mobile */
  }

  .inform4-left h2 {
    font-size: 1.5rem; /* Further reduce font size for mobile */
  }

  .inform4-left hr {
    margin: 0.5rem 0;
  }

  .bullet-points li {
    margin-bottom: 4rem; /* Further reduce margin for mobile */
  }

  .bullet-points li::before {
    width: 40px; /* Set the width of the bullet icon */
    height: 40px; /* Set the height of the bullet icon */
  }

  .inform4-right img {
    width: 100%; /* Adjust image size for mobile */
  }
}
