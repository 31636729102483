/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: ivory;
}

.App {
  text-align: center;
}
