/* Main page container */
.advantage-page {
  padding: 20px;
  animation: slideUp 1s ease-out;
}

.advantage-page h2{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  color: rgb(76, 77, 85);
  font-size: 1.5rem; /* Reduce font size for tablets and mobile */
  margin-bottom: 0.5rem;
}

.advantage-page p{
  font-family:Roboto Condensed;
  font-size: 1rem;
  color: rgb(128, 116, 116);
}


/* Main header */
.main-header {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: rgba(63, 62, 62, 0.726);
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  animation: fadeIn 1.5s ease-out;
}

/* Container for the top and bottom divs */
.top-div, .bottom-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px; /* Gap between each div */
  margin-bottom: 20px;
}

/* Each advantage box */
.advantage-box {
  flex: 1 1 calc(33.333% - 10px); /* 3 cards per row with 10px gap */
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
  animation: riseUp 0.8s ease-out;
  transition: transform 0.3s ease;
}

.advantage-box:hover {
  transform: translateY(-10px); /* Hover effect for a slight lift */
}

/* Icon styling */
.icon {
  font-size: 2.5rem;
  color: #ff6600;
  margin-bottom: 10px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .top-div, .bottom-div {
    flex-direction: column;
  }

  .advantage-box {
    flex: 1 1 100%;
    margin-bottom: 10px;
    animation: riseLeftToRight 0.8s ease-out;
  }
}

/* Animations */
@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes riseUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes riseLeftToRight {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
